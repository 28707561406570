<template>
  <section>
    <el-form
      class="el-card"
      ref="form"
      :model="model"
      :disabled="loading"
      @submit.native.prevent="login"
    >
      <div class="el-card__header">
        <h1 class="text-center">
          {{ $t('login') }}
        </h1>
      </div>

      <div class="el-card__body">
        <el-form-item
          prop="username"
          :show-message="false"
          :label="$t('username')"
          required
        >
          <el-input
            prefix-icon="el-icon-user"
            v-model="model.username"
          />
        </el-form-item>

        <el-form-item
          prop="password"
          :show-message="false"
          :label="$t('password')"
          required
        >
          <el-input
            prefix-icon="el-icon-key"
            v-model="model.password"
            show-password
          />
        </el-form-item>

        <div>
          <el-checkbox
            class="dir-fl-left"
            v-model="model.rememberMe"
          >
            {{ $t('remember-me') }}
          </el-checkbox>
        </div>

        <br>
        <br>

        <el-button
          class="button bg-gd-pm"
          size="big"
          native-type="submit"
          :icon="loading?'el-icon-loading':''"
        >
          <fai
            v-show="!loading"
            icon="sign-in-alt"
          />
          {{ $t('login') }}
        </el-button>

        <br>
        <br>
        <div class="text-center">
          <router-link to="password-reset">
            {{ $t('forgot-password') }}
          </router-link>
        </div>
      </div>
    </el-form>
  </section>
</template>

<script>
import ElCheckbox from 'element-ui/packages/checkbox/src/checkbox';

import 'element-ui/packages/theme-chalk/src/checkbox.scss';

export default {
  components: { ElCheckbox },

  data() {
    return {
      model: {
        username: null,
        password: null,
        rememberMe: false,
      },
      loading: false,
    };
  },

  methods: {
    async login() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        return;
      }

      // Show spinner
      this.loading = true;

      // Get token

      const {
        username,
        password,
        rememberMe,
      } = this.model;
      const success = await this.$auth.login(username, password, rememberMe);

      // Hide spinner
      this.loading = false;

      if (!success) {
        return;
      }

      this.redirect();
    },

    redirect() {
      this.$router.push({
        name: this.$route.query.redirectTo || 'home',
      });
    },
  },

  created() {
    if (this.$store.state.auth.user) {
      this.redirect();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/home/styles/var";

h1 {
  margin: 0;
}

.el-card {
  height: calc(100vh - #{ ($header-padding * 2) + $header-height } - 2px);
}

.button {
  width: 100%;
}

.error {
  background: #fb5252;
  color: #fff;
  font-size: .9em;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0 20px;
}

@media all and (min-width: $--md) {
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: calc(100vh - #{ ($header-padding * 2) + $header-height });
    background-image: url("../../../assets/images/background.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .el-card {
    width: 400px;
    height: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
